
import { defineComponent, computed } from "vue";
import { useFindAllCategories } from "@/graphql/category/find.all.categories";
import { getImage, makeTreeTable } from "@/graphql/utils/utils";

export default defineComponent({
  name: "TerminalCategories",
  props: ["modelValue"],
  emits: ["update:modelValue"],
  setup() {
    const { categories } = useFindAllCategories();
    return {
      nodes: computed(() => makeTreeTable(categories.value, 1)),
      getImage,
    };
  },
});
