import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-4e532174"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "card p-shadow-0" }
const _hoisted_2 = { class: "p-mb-0" }
const _hoisted_3 = {
  class: "p-mb-3",
  style: {"font-size":"12px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Column = _resolveComponent("Column")!
  const _component_TreeTable = _resolveComponent("TreeTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.$t("terminal.categorySettings")), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("terminal.selectCategories")), 1),
    _createVNode(_component_TreeTable, {
      value: _ctx.nodes,
      selectionMode: "checkbox",
      selectionKeys: _ctx.modelValue,
      "onUpdate:selectionKeys": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
      class: "p-datatable-sm"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "label",
          header: _ctx.$t('category.name'),
          expander: true
        }, null, 8, ["header"])
      ]),
      _: 1
    }, 8, ["value", "selectionKeys"])
  ]))
}